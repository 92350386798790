<template lang="pug">
Dropdown#fontFamily(
  v-model="fontFamily"
  searchable
  fontOptions
  :label="$t('fontFamily')"
  :items="fonts"
  :layout="layout"
  :customClass="dropdownClass"
  :editMobile="editMobile"
  @input="$emit('input', $event)"
)
  template(#afterDropdown v-if="manage")
    OmButton(iconOnly icon="upload" @click="$modal.show('custom-font-upload-v2')")
</template>
<script>
  import { getMostUsedFonts } from '@/editor/util/getMostUsedFonts';
  import { mapGetters, mapState } from 'vuex';
  import Dropdown from '../Dropdown/Dropdown.vue';

  export default {
    components: {
      Dropdown,
    },
    props: {
      value: {
        type: String,
      },
      layout: {
        type: String,
        default: 'col',
        options: ['row', 'col'],
        validator: (value) => {
          return ['row', 'col'].includes(value);
        },
      },
      manage: {
        type: Boolean,
        default: false,
      },
      editMobile: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      ...mapState(['themeKit']),
      ...mapGetters(['installedFonts', 'isThemeKit']),
      ...mapGetters({
        userId: 'databaseId',
      }),
      defaultFont() {
        return this.isThemeKit ? this.themeKit.fonts[0] : 'open-sans';
      },
      fonts() {
        return getMostUsedFonts(this.userId, this.installedFonts).map(({ value, label }) => ({
          value,
          text: label,
        }));
      },
      fontFamily: {
        get() {
          const valueHasInMostUsedFonts = this.fonts.find(({ value }) => value === this.value);
          return valueHasInMostUsedFonts ? this.value : this.defaultFont;
        },
        set(value) {
          this.$emit('input', value);
        },
      },
      dropdownClass() {
        return this.manage ? 'with-button' : '';
      },
    },
    mounted() {
      this.$bus.$on('uploadedBrandFont', ({ value }) => {
        this.fontFamily = value;
      });
    },
  };
</script>

<style lang="sass">
  #fontFamily
    &.select-input
      overflow: hidden
      white-space: nowrap
      text-overflow: ellipsis
      max-width: 5rem
      ~ .select-selection
        max-width: 5rem
  .select-fontFamily
    &.with-button
      .select-input
        max-width: 9.5rem !important
        ~ .select-selection
          max-width: 9.5rem !important
</style>
